<template>
  <div class="storemall">
    <page-nav title="易备店铺" />
    <header>
      <form action="/">
        <van-search
          v-model="searchKey"
          show-action
          placeholder="请输入店铺名称"
          @search="onSearch"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </form>
    </header>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="list.length > 0 ? '没有更多了' : ''"
      @load="fetchSearch"
    >
      <div class="store-list">
        <div class="store-list-item"  v-for="item in list" :key="item.id">
          <div class="store-info" @click="onClickStore(item.id)">
            <img class="store-logo" :src="item.logoImg" />
            <div class="store-title van-multi-ellipsis--l2">{{item.title}}</div>
          </div>
          <div class="goods-recommend">
            <ul>
              <li v-for="rc in item.productList.slice(0, 3)" :key="rc.productId" @click="onClickRcItem(rc.productId)">
                <div class="rc-item">
                  <div class="img-wrap"><img :src="rc.titleImg" /></div>
                  <div class="name">{{ rc.title }}</div>
                  <div v-if="!!rc.price && parseInt(rc.price) != 0" class="buy-wrap">
                    ￥<span class="num">{{rc.price}}</span>
                  </div>
                  <div v-else class="buy-wrap">买方报价</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </van-list>
    <van-empty v-show="!loading && searched && list.length == 0" description="暂无数据" />
    <GoTop />
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast, Lazyload } from 'vant';
import Fetch from '@/common/fetch';
import { QueryStoreList } from '@/common/interface';
import GoTop from '../components/GoTop.vue';
import PageNav from '../components/PageNav.vue';

Vue.use(Toast);
Vue.use(Lazyload);

export default {
  name: "StoreMall",
  components: { GoTop, PageNav },
  data() {
    return {
      curPage: 1,
      searchKey: '',
      searched: false,  //索索过，显示排序面板
      loading: false,
      finished: false,
      list: [],
    };
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    onSearch() {
      this.curPage = 1;
      this.fetchSearch();
    },

    onBack() {
      this.$router.go(-1);
    },


    onConfirm() {
      this.$refs.item.toggle();
      this.curPage = 1;
      this.fetchSearch();
    },

    fetchSearch() {
      this.searched = true;
      const page = this.curPage;

      const params = {
        pageNum: page,
        title: this.searchKey,
        pageSize: 20,
      };
      Fetch.post(QueryStoreList, params).then((res) => {
        const resList = res.list || [];
        const finished = resList.length < 20;
        const newList = page == 1 ? resList : this.list.concat(resList);
        this.curPage = page + 1;
        this.list = newList;
        this.loading = false;
        this.finished = finished;
      }).catch((err) => {
        Toast(err.msg || '请求失败');
      });
    },

    onClickStore(storeId) {
      // this.$router.push('/product?id=' + productId);
      window.location.href = 'https://etb618.com/store?id=' + storeId
    },

    onClickRcItem(productId) {
      this.$router.push('/product?id=' + productId);
    },
  },
}
</script>

<style lang="scss" scoped>
.store-list {
  margin: 15px 0;
  .store-list-item {
    padding: 0 16px;
    margin-bottom: 10px;
    background: #fff;
    .store-info {
      display: flex;
      padding: 10px 0;
      .store-logo {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
    .goods-recommend {
      min-height: 20px;
      ul {
        position: relative;
        overflow: hidden;
        li {
          position: relative;
          list-style-type: none;
          float: left;
          width: 32.16%;
          margin-top: 5px;
        }
        li:not(:nth-child(3n)) {
          margin-right: 1.76%;
        }
      }
      .rc-item {
        .img-wrap {
          position: relative;
          width: 100%;
          padding-top: 100%;
          border-radius: 6px;
          overflow: hidden;
          img {
            position: absolute;
            max-width: 100%;
            max-height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            border-radius: 6px;
          }
        }
        .name {
          padding: 7px 0 0;
          height: 3em;
          line-height: 1.5em;
          color: #333;
          font-size: 12px;
          font-weight: 400;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .buy-wrap {
          position: relative;
          height: 26px;
          line-height: 26px;
          color: #ff0069;
          font-size: 12px;
          .num {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>